.glass-container {
  background: rgba(255, 255, 255, 0.01);
  border-radius: 10px;
  padding: 20px;
  min-width: 300px;
  max-width: 1200px; 
  backdrop-filter: blur(75px);
  border: 1px solid rgba(255, 255, 255, 0.2); 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: white; 
  margin: auto
}
.glass-container-email {
  background: rgba(255, 255, 255, 0.474);
  border-radius: 10px;
  /* padding: 20px; */
  min-width: 300px; 
  max-width: 900px;
  backdrop-filter: blur(25px);
  border: 1px solid rgba(255, 255, 255, 0.2); 
  box-shadow: 0 7px 9px rgba(6, 5, 5, 0.1);
  font-family: "Poppins",
  /* color: white;  */
}